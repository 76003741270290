@import "../../mixins.scss";

.documents {
    padding: 20px 176px 60px;
    display: flex;
    flex-direction: column;

    
    @include for-1100 {
        padding: 2px 16px 40px;
    }

    .content {

        .date {
            font-family: "RobotoMono";
            margin-top: 40px;
            font-size: 18px;
            line-height: 24px;
            color: #7F7C8A;
        }

        .blocks {
            margin-top: 8px;

            .block {
                margin-top: 56px;

                .text {
                    margin-top: 24px;
                    font-size: 16px;
                    line-height: 24px;
                }

                h4 {
                    margin-top: 40px;
                }

                ul {
                    margin: 0px 0px 0px;
                    padding: 0px;
                    list-style: none;

                    li {
                        margin-top: 24px;
                        padding: 0px;
                        line-height: 24px;
                        font-size: 16px;
                        display: inline-flex;

                        &::before {    
                            margin-right: 8px;
                            content: "•";
                            font-size: 28px;
                            color: #F26D6B;
                        }
                    }
                }
            }
        }
    }
}