@import "../../mixins.scss";

.FeaturesBlock {
    background-color: #171616;
    padding: 108px 176px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include for-1100 {
        padding: 40px 16px;
    }
    
    .content {

        h1 {
            color: #FFF;
            text-align: center;
        }

        h4 {
            margin-top: 40px;
            color: #7F7C8A;
            text-align: center;
        }

        .tiles {
            margin-top: 76px;
            display: grid;
            grid-template-columns: 1fr;
            gap: 40px 24px;

            @include for-680 {
                margin-top: 40px;
                display: flex;
                flex-direction: column;
            }

            .tile {
                background: #252323;
                box-shadow: 8px 8px 0px #000000;
                padding: 24px;
                display: grid;
                grid-template-columns: 56px auto;
                gap: 24px;
                align-items: flex-start;
                justify-content: flex-start;
                box-sizing: border-box;

                @include for-1100 {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                }

                .textContent {
                    .text {
                        font-size: 16px;
                        line-height: 24px;
                        margin-top: 16px;
                    }

                    ul {
                        margin: 8px 0px 0px;
                        padding: 0px;
                        list-style: none;

                        .mt {
                            margin-top: 16px;
                        }

                        li {
                            padding: 0px;
                            line-height: 24px;
                            font-size: 16px;

                            &::before {    
                                position: relative;
                                top: 4px;
                                margin-right: 8px;
                                content: "•";
                                font-size: 28px;
                                color: #F26D6B;
                            }
                        }
                    }
                }
            }
        }

        .bottomText {
            margin-top: 76px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            
            .bottomContent {
                position: relative;
                padding: 12px 40px;
                border: solid 3px #F26D6B;
                color: #F26D6B;
                background-color: #171616;
                z-index: 100;
                width: 964px;
                height: 56px;
                box-sizing: border-box;

                @include for-1100 {
                    width: 100%;
                    height: auto;
                }

                .text {
                    font-family: "RobotoMono";
                    font-size: 20px;
                    line-height: 26px;
                    letter-spacing: -0.03em;
                    color: #F26D6B;
                }
            }

            .border {
                position: absolute;
                z-index: 99;
                border: 3px solid #6343E4;
                top: 7px;
                left: 0px;
                box-sizing: border-box;
            }

        }
    }
}