@import "../../mixins.scss";

.GreetingBlock {
    padding: 228px 176px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include for-1100 {
        padding: 165px 16px;
    }

    .content {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: auto auto;
        align-items: flex-start;
        justify-content: center;
        gap: 32px;

        @include for-1100 {
            display: flex;
            flex-direction: column;
        }

        .prefix {
            font-family: "RobotoMono";
            color: #F26D6B;
            font-size: 40px;
            line-height: 53px;
            font-weight: bold;

            @include for-1100 {
                font-size: 26px;
                line-height: 32px;
            }
        }

        .text {
            text-align: center;

            .purple {
                color: #6343E4;
            }

            .coral {
                color: #F26D6B;
            }

            .row {
                font-family: "RobotoMono";
                font-size: 40px;
                line-height: 53px;
                position: relative;

                @include for-1100 {
                    font-size: 26px;
                    line-height: 32px;
                }

                .robot {
                    position: absolute;
                    margin-left: 8px;
                    
                    @include for-1100 {
                        width: 32px;
                        height: 32px;
                    }

                    &.point {
                        margin-left: 24px;

                        @include for-1100 {
                            margin-left: 8px;
                        }
                    }
                }

                &.mt {
                    margin-top: 42px;
                }

                &.red {
                    color: #F26D6B;
                }

                &.cursor {
                    border-right: 3px solid #F26D6B;
                    display: inline-block;
                    padding-right: 105px;

                    @include for-1100 {
                        padding-right: 50px;
                    }
                }
            }
        }
    }
}