@import "./mixins.scss";

@font-face {
    font-family: "RobotoMono";
    src: url("./asserts/fonts/RobotoMono.ttf");
}

@font-face {
    font-family: "Roboto";
    src: url("./asserts/fonts/Roboto.ttf");
}

* {
    font-family: "Roboto";
    letter-spacing: -0.03em;
    color: #FFFFFF;
}

body {
    margin: 0px;
    padding: 0px;
    background-color: #0E0D0D;

    h1 {
        font-family: "RobotoMono";
        font-size: 40px;
        line-height: 53px;
        letter-spacing: -0.03em;
        margin: 0px;
        padding: 0px;

        @include for-1100   {
            font-size: 26px;
            line-height: 32px;
        }
    }

    h3 {
        font-family: "RobotoMono";
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.03em;
        margin: 0px;
        padding: 0px;

        @include for-1100 {
            font-size: 20px;
            line-height: 26px;
        }
    }

    h4 {
        font-family: "RobotoMono";
        font-size: 20px;
        line-height: 26px;
        letter-spacing: -0.03em;
        margin: 0px;
        padding: 0px;

        @include for-1100 {
            font-size: 16px;
            line-height: 21px;
        }
    }

    .content {
        @include for-1100 {
            width: auto ;
        }
    }

    button {
        outline: none;
        border: 0px;
        display: flex;
        flex-direction: row;
        gap: 12px;
        padding: 17px 24px;
        cursor: pointer;

        .text {
            position: relative;
            top: -2px;
            font-size: 20px;
            line-height: 26px;
            font-family: "RobotoMono";
        }

        &:active {
            box-shadow: inset 0px 0px 4px 1px #00000088;
        }
    }

    .pink {
        color: #F26D6B;
    }

    .hide {
        display: none !important;
    }
}