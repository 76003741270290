.TopMenuMobile {
    position: relative;

    .menu {
        cursor: pointer;
    }

    .menuBlock {
        background-color: #0E0D0D;
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0px;
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;
        padding-top: 144px;

        .menuItem {
            font-size: 26px;
            line-height: 32px;
            color: #FFFFFF;
            cursor: pointer;
        }

        a {
            text-decoration: none;
        }

        .button {
            position: fixed;
            bottom: 0px;
            left: 0px;
            padding: 16px;
            width: 100%;
            box-sizing: border-box;

            button {
                width: 100%;
                align-items: center;
                justify-content: center;
    
                .image {
                    width: 24px;
                    height: 24px;
                    background-image: url("../../asserts//svg/slack.svg");
                    background-repeat: no-repeat;
                }
    
                .text {
                    color:#6343E4;
                }
    
                &:active {
                    background-color: #F26D6B;
    
                    .image {
                        background-image: url("../../asserts//svg/slack_w.svg");
                    }
    
                    .text {
                        color: #FFF;
                    }
                }
            }
    
        }
    }
}