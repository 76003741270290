@import "../../mixins.scss";

.AboutBlock {
    background: #171616;
    padding: 108px 176px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include for-1100 {
        padding: 40px 16px 40px;
    }

    .content {

        h1 {
            text-align: center;
        }

        .tiles {
            display: grid;
            grid-template-columns: 1fr;
            gap: 24px;
            margin-top: 64px;

            @include for-680 {
                display: flex;
                flex-direction: column;
                margin-top: 40px;
            }

            .tile{
                background-color: #FFF;
                padding: 40px;
                box-shadow: 8px 8px 0px #6343E4;

                @include for-1100 {
                    padding: 24px 24px 40px;
                }

                .images{
                    display: flex;
                    flex-direction: row;
                    gap: 18px;
                    justify-content: center;

                    @include for-680 {
                        gap: 4px;
                    }

                    img {
                        width: 76px;
                        height: 76px;

                        @include for-680 {
                            width: 32px;
                            height: 32px;
                        }
                    }
                }

                .text {
                    margin-top: 32px;
                    font-family: "RobotoMono";
                    font-size: 18px;
                    line-height: 24px;
                    color: #0E0D0D;

                    @include for-1100 {
                        font-size: 18px;
                        line-height: 24px;
                    }
                }

                .buttons {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 24px;
                    margin-top: 32px;

                    @include for-680 {
                        display: flex;
                        flex-direction: column;
                        align-content: center;
                    }

                    a {
                        text-decoration: none;
                    }

                    button {
                        justify-content: center;
                        width: 100%;
                        background-color: #6343E4;
                        

                        @include for-340 {
                            width: 240px;
                        }

                        .text {
                            color: #FFF;
                            margin: 0px;

                            @include for-340 {
                                font-size: 14px;
                            }
                        }

                        .image {
                            width: 24px;
                            height: 24px;
                        }

                        &.mail {
                            .image {
                                background-image: url("../../asserts/svg/mail.svg");
                            }
                        }

                        &.tg {
                            .image {
                                background-image: url("../../asserts/svg/tg.svg");
                            }
                        }

                        &:hover {
                            background-color: #F26D6B;
            
                            .text {
                                color: #FFF;
                            }
                        }
                    }
                }
            }
        }

        .cards {
            margin-top:80px;
            margin-bottom: 60px;

            @include for-1100 {
                margin-top:40px;
                margin-bottom: 0;
            }
        }

        .farewell {
            font-family: "RobotoMono";
            margin-top: 40px;
            font-size: 32px;
            line-height: 42px;
            text-align: center;
            color: #F26D6B;

            @include for-1100 {
                font-size: 24px;
                line-height: 32px;
            }
        }

        .cards {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .row {
                width: 100%;
                display: flex;
                margin-top:40px;
                justify-content: center;

                @include for-1100 {
                    flex-direction: column;
                    margin-top:0
                }
            }

            .card {
                text-align: start;
                position: relative;

                @include for-1100 {
                    margin-top:30px;
                }

                .card-content {
                    font-family: "RobotoMono";
                    font-style: normal;
                    font-weight: 500;
                    .card-title {
                        font-size:40px;
                        font-family: "RobotoMono";
                        font-style: normal;
                        font-weight: 500;
                    }

                    .card-text {
                        font-family: "RobotoMono";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                    }
                }

                &.card5 {
                    width:350px;
                    margin-right:38px;

                    @include for-1100 {
                        min-width:280px;
                        width:100%;
                        max-width:350px;
                        margin-right: 0;
                    }

                    & > div.card-content {
                        border: 3px solid #F26D6B;
                    }

                    & > div.card-bg {
                        border: 3px solid #6343E4;
                        width: 267px;
                        height: 83px;

                        @include for-1100 {
                            width:calc(100% - 84px);
                            height: calc(100% - 57px);
                        }
                    }

                    & > div > div {
                        color: #F26D6B;
                    }
                 }

                &.card100 {
                    width:280px;
                    margin-left:38px;

                    @include for-1100 {
                        margin-left: 0;
                        min-width:260px;
                        width:100%;
                        max-width:280px;
                    }

                    & > div.card-content {
                        border: 3px solid #6343E4;
                    }

                    & > div.card-bg {
                        border: 3px solid #FFFFFF;
                        width: 196px;
                        height: 59px;

                        @include for-1100 {
                            width:calc(100% - 84px);
                            height: calc(100% - 57px);
                        }
                    }

                    & > div > div {
                        color: #6343E4;
                    }
                }

                &.card30 {
                    width:600px;

                    @include for-1100 {
                        min-width:280px;
                        width:100%;
                        max-width:600px;
                    }

                    & > div.card-content {
                        border: 3px solid #FFFFFF;
                    }

                    & > div.card-bg {
                        border: 3px solid #6343E4;
                        width: 517px;
                        height: 58px;

                        @include for-1100 {
                            width:calc(100% - 84px);
                            height: calc(100% - 57px);
                        }
                    }

                    & > div > div {
                        color: #FFFFFF;
                    }
                }

                &-content {
                    display: block;
                    z-index:5;
                    background: #171616;
                    padding:16px 40px;
                    z-index: 3;
                    position: relative;
                }

                .card-bg {
                    display: block;
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    border-radius: 2px;
                    z-index: 1;
                    padding: 26px 40px;
                }
            }

            //.card100 {
            //    width: 290px;
            //    border: 3px solid #6343E4;
            //    filter: drop-shadow(8px 8px 0px #FFFFFF);
            //}
            //
            //.card30 {
            //    width: 600px;
            //    border: 3px solid #FFFFFF;
            //    filter: drop-shadow(8px 8px 0px #6343E4);
            //}
        }
    }
}