@import "../../mixins.scss";

.PriceBlock {
    padding: 100px 176px 130px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include for-1100 {
        padding: 90px 16px 44px;
    }
    
    .content {
        h1 {
            text-align: center;
        }

        .tiles-wrapper {
            display: flex;
            @include for-1100 {
                flex-direction: column;
            }
        }

        .tile {
            margin-top: 64px;
            margin-left:12px;
            margin-right: 12px;
            box-sizing: border-box;
            background-color: #6343E4;
            box-shadow: 8px 8px 0px #FFFFFF;
            padding: 36px 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 32px;
            max-width:530px;
            min-width: 410px;


            @include for-1100 {
                margin-top: 40px;
                margin-left: 0;
                margin-right: 0;
                flex-direction: column;
                align-items: flex-start;
                padding: 40px 24px;
                width: 100%;
                min-width: 260px;
            }
        }
    }

    .icon {
        width: 68px;
    }

    .text {
        flex: 1;
        
        .price {
            font-family: "RobotoMono";
            font-size: 32px;
            line-height: 42px;
            color: #FFFFFF;
            font-weight: 500;

            @include for-1100 {
                font-size: 24px;
                line-height: 32px;
            }
        }

        .description {
            font-family: "RobotoMono";
            font-size: 20px;
            line-height: 26px;
            letter-spacing: -0.03em;
            color: #FFFFFF;

            @include for-1100 {
                margin-top: 16px;
                font-size: 16px;
                line-height: 21px;
            }
        }
    }

    .buttons {

        @include for-1100 {
            width: 100%;
        }

        a {
            text-decoration: none;
        }

        button {
            margin-left: 32px;

            @include for-1100 {
                margin-left: 0px;
            }

            .image {
                width: 24px;
                height: 24px;
                background-image: url("../../asserts//svg/slack.svg");
                background-repeat: no-repeat;
            }

            .text {
                color:#6343E4;
            }

            &:hover {
                background-color: #F26D6B;

                .image {
                    background-image: url("../../asserts//svg/slack_w.svg");
                }

                .text {
                    color: #FFF;
                }
            }
        }
    }
}