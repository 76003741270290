@mixin for-1100 {
    @media screen and (max-width: 1100px) {
        @content;
    }
}

@mixin for-680 {
    @media screen and (max-width: 680px) {
        @content;
    }
}

@mixin for-340 {
    @media screen and (max-width: 340px) {
        @content;
    }
}