@import "../../mixins.scss";

.MainHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 40px 64px 40px 20px;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    box-sizing: border-box;
    background-color: #0E0D0D;
    z-index: 150;
    transition: all 0.1s linear;

    @include for-1100 {
        padding: 20px 16px 20px 0;
    }

    .logo {
        height: 56px;
        cursor: pointer;
        display: flex;
        align-items: center;

        @include for-1100 {
            margin-left: -22px;
        }

        &:hover {
            color: red;
            svg {
                path {
                    fill: #F26D6B;
                }
            }
        }
    }

    .menu {
        justify-content: flex-end;
        flex: 1;
        display: flex;

        @include for-1100 {
            flex: auto;
            width: 27px;
        }
    }
}