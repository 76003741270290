@import "../../mixins.scss";

.MainFooter {
    background-color: #252323;;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 112px 0px 108px;
    padding-left: 32px;
    padding-right: 32px;

    @include for-1100 {
        padding: 44px 16px 40px;
        display: block;
    }

    .right-block {
        display: flex;
        justify-content: center;
        width:100%;
        gap: 20px;

        .first {
            width: 416px;

            @include for-1100 {
                width: 33%;
            }

            @include for-680 {
                width: auto;
            }
        }

        .second {
            width: 420px;

            @include for-1100 {
                width: 33%;
            }

            @include for-680 {
                width: auto;
            }
        }

        .third {
            width: 240px;

            @include for-1100 {
                width: 33%;
            }

            @include for-680 {
                width: auto;
            }
        }
    }

    .content {
        width: 100%;

        .rights {
            font-family: "RobotoMono";
            font-size: 16px;
            margin-top: 80px;
            width: 416px;
        }
    }
}