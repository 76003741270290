.TopMenu {
    display: flex;
    flex-direction: row;
    gap: 56px;

    .menuItem {
        font-family: "RobotoMono";
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        cursor: pointer;

        &:hover {
            color: #F26D6B;
        }
    }
}