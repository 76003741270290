@import "../../mixins.scss";

.BottomMenu {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    justify-content: center;

    @include for-680 {
        flex-direction: column;
    }

    .column {

        &.first {
            width: 416px;

            @include for-1100 {
                width: 33%;
            }

            @include for-680 {
                width: auto;
            }
        }

        &.second {
            width: 420px;

            @include for-1100 {
                width: 33%;
            }

            @include for-680 {
                width: auto;
            }
        }

        &.third {
            width: 240px;

            @include for-1100 {
                width: 33%;
            }
            
            @include for-680 {
                width: auto;
            }
        }

        ul {
            margin: 0px;
            padding: 0px;
            list-style: none;

            @include for-1100 {
                margin-bottom: 40px;
            }

            li {
                display: block;
                width: max-content;
                margin-top: 24px;

                &.link {
                    font-size: 18px;
                    line-height: 24px;
                    font-family: "RobotoMono";
                    text-decoration: none;
                    cursor: pointer;

                    &:hover {
                        color: #F26D6B;
                    }
                }

                a {
                    font-size: 18px;
                    line-height: 24px;
                    font-family: "RobotoMono";
                    text-decoration: none;

                    &:hover {
                        color: #F26D6B;
                    }
                }
            }
        }

        .block {
            
            .row {
                margin-top: 24px;
                font-size: 18px;
                line-height: 24px;
                font-family: "RobotoMono";
            }
        }
    }
}