@import "../../mixins.scss";

.MainLayout {
    .outlet {
        margin-top: 136px;

        @include for-1100 {
            margin-top: 96px;
        }
    }
}